import React, { createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotFoundPage } from "./pages/not-found-page";

import { ProfilePage } from "./pages/profile-page/profile-page";
import { LandingPage } from "./pages/landing-page/landing-page";
import { ActivateUserPage } from "./pages/activate-user-page/activate-user-page";
import { AvatarsPage } from "./pages/edit-profile/avatar-page";
import { QueryParamProvider } from "use-query-params";
import { TeachersPage } from "./pages/teachers-page/teachers-page";
import { TeacherDetailPage } from "./pages/teachers-detail-page/teacher-detail-page";
import { ForgotPasswordPage } from "./pages/pw-management-pages/forgot-password-page/forgot-password-page";
import { ResetPasswordPage } from "./pages/pw-management-pages/reset-password-page/reset-password-page";
import { ImprintPage } from "./pages/info-pages/ImprintPage";
import { AGBPage } from "./pages/info-pages/AGBPage";
import { HelpPage } from "./pages/info-pages/HelpPage/HelpPage";
import { ProtectedRoute } from "./components/common/ProtectedRoute";
import { HomePage } from "./pages/home-page/home-page";
import { useQuery } from "@apollo/client";
import { GET_USER_HEADER } from "./apollo-client/queries";

export const UserContext = createContext(null);

function Routes() {
  const { data: userData } = useQuery(GET_USER_HEADER);

  return (
    <UserContext.Provider value={userData?.user}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/registration">
              <LandingPage />
            </Route>
            <Route exact path={"/forgot-password"}>
              <LandingPage />
            </Route>
            <Route exact path={"/password-reset"}>
              <LandingPage />
            </Route>

            <Route exact path="/activate-user">
              <ActivateUserPage />
            </Route>
            <Route exact path={"/forgot-password-old"}>
              <ForgotPasswordPage />
            </Route>
            <Route exact path={"/password-reset-old"}>
              <ResetPasswordPage />
            </Route>
            <Route exact path={"/imprint"}>
              <ImprintPage />
            </Route>
            <Route exact path={"/agb"}>
              <AGBPage />
            </Route>
            <Route exact path={"/help"}>
              <HelpPage />
            </Route>

            <Route
              exact
              path="/courses/:id/teachers"
              component={TeachersPage}
            />
            <Route
              exact
              path="/courses/:id/teachers/:teachingId"
              component={TeacherDetailPage}
            />
            <ProtectedRoute path={"/home"} component={HomePage} />
            <ProtectedRoute
              path={"/profile/:id/my-teachings"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id/my-content"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id/my-groups"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id/edit"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id/edit/picture-change"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id/edit/change-pw"}
              component={ProfilePage}
            />
            <ProtectedRoute
              exact
              path={"/profile/:id/help"}
              component={ProfilePage}
            />
            <Route
              exact
              path={"/profile/edit/add-photo"}
              component={AvatarsPage}
            />
            <Route exact path={"*"} component={NotFoundPage} />
          </Switch>
        </QueryParamProvider>
      </Router>
    </UserContext.Provider>
  );
}

export default Routes;
