import { MainLayout } from "../../components/layout/main-layout";
import { ContentContainer } from "../../components/layout/content-container";
import { GeneralStartTutoringMotivation } from "./GeneralStartTeachingMotivation";
import styled from "styled-components";
import { HomePageNotificationsBox } from "./HomePageNotificationsBox";
import { HomePageTeachingsBox } from "./HomePageTeachingsBox";

export const HomePage = () => {
  return (
    <MainLayout loading={false}>
      <ContentContainer>
        <ContentItemWrapper>
          <GeneralStartTutoringMotivation />
          <HomePageNotificationsBox />
          <HomePageTeachingsBox />
        </ContentItemWrapper>
      </ContentContainer>
    </MainLayout>
  );
};

const ContentItemWrapper = styled.div`
  width: 75%;
  margin: 0 auto;

  &:last-child {
    padding-bottom: 150px;
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.secondBreakpoint}px) {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
