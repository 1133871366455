import React, { useState, useRef } from "react";
import styled from "styled-components";
import { BlagoomStandardLogo } from "../../icons/icons-svg/blagoom-standard-logo";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useOutsideMenuCloserWithFunction } from "../../../helpers";
import { GET_USER_HEADER } from "../../../apollo-client/queries";
import PropTypes from "prop-types";
import { AboComponent } from "./abo-component/abo-component";
import { NotificationsComponent } from "./notifications/notifications-component";
import { LoginButton } from "./login-button";
import { CentralNavigationBar } from "./central-navigation-bar";
import { useApolloClient, useQuery } from "@apollo/client";
import { ProfileButton } from "./profile-button";
import { useIsMobile } from "../../../hooks/state-checkers/use-is-mobile";
import { useIsListPage } from "../../../hooks/state-checkers/use-is-list-page";
import { NOTIFICATION_SUBSCRIPTION } from "./notifications/subscriptions";

const landingPageRoutes = [
  "/",
  "/registration",
  "/activate-user",
  "/forgot-password",
  "/password-reset",
];

export const Header = ({
  title,
  university,
  subscriberCount,
  loading,
  color,
  backgroundColor,
  hideNavigation,
  position,
  course,
  props,
}) => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const wrapperRefDropdown = useRef(null);
  useOutsideMenuCloserWithFunction(wrapperRefDropdown, () =>
    setIsProfileMenuOpen(false)
  );

  const {
    data: userData,
    error: getUserError,
    loading: loadingUserData,
    subscribeToMore,
  } = useQuery(GET_USER_HEADER);

  const isLoggedIn = Boolean(localStorage.getItem("token"));
  const match = useRouteMatch();
  const isMobile = useIsMobile();
  const isListPage = useIsListPage();
  const history = useHistory();

  const client = useApolloClient();

  const goToTeachersPage = () => {
    if (course) {
      history.push(`/courses/${course._id}/teachers`);
    }
  };

  return (
    <HeaderContainer>
      <TopWrapper
        backgroundColor={backgroundColor}
        position={position}
        isOnLandingPage={landingPageRoutes.includes(match.path)}
      >
        <LeftWrapper>
          <StyledLink to={"/"}>
            <StyledBlagoomStandardLogo color={color} />
          </StyledLink>
        </LeftWrapper>

        <HideOnMobileBox>
          {!hideNavigation && <CentralNavigationBar />}
        </HideOnMobileBox>

        <RightWrapperMobileFiller />
        <RightWrapperWeb id={"header_right_wrapper"}>
          {isLoggedIn && !loadingUserData && !getUserError && (
            <>
              <NotificationsComponent
                getUserData={userData}
                notificationsCount={userData.user.newNotificationsCount}
                subscribeToMoreNotifications={subscribeToMore({
                  document: NOTIFICATION_SUBSCRIPTION,
                  updateQuery: (prev, { subscriptionData }) => {
                    /*
                      Merging the new data to prev data is a better option, but for some reasons multiple events are send sometimes
                     */
                    client.refetchQueries({ include: [GET_USER_HEADER] });
                  },
                })}
              />
              <ProfileOrLoginDropdownWrapper ref={wrapperRefDropdown}>
                <ProfileButton getUserData={userData} />
              </ProfileOrLoginDropdownWrapper>
            </>
          )}
        </RightWrapperWeb>
        {!isLoggedIn && <LoginButton position={position} props={props} />}
      </TopWrapper>

      {!loading && title !== undefined && (
        <SubBarWrapper>
          {isMobile && isListPage && <CentralNavigationBar />}
          <CourseTitleAboWrapper>
            <TitleUniWrapper>
              <CourseTitle onClick={goToTeachersPage}>{title}</CourseTitle>
              <University>{university}</University>
            </TitleUniWrapper>
            {isListPage && !loadingUserData && (
              <AboComponent
                subscriberCount={subscriberCount}
                match={props.match}
              />
            )}
          </CourseTitleAboWrapper>
        </SubBarWrapper>
      )}
    </HeaderContainer>
  );
};

Header.defaultProps = {
  backgroundColor: "#fff",
};

Header.propTypes = {
  backgroundColor: PropTypes.string,
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
`;

const TopWrapper = styled.div`
  width: 100%;
  position: ${(props) => (props.position ? props.position : "fixed")};
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.isOnLandingPage ? "transparent" : "#ffffff"};
  z-index: 8;
`;

const HideOnMobileBox = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const LeftWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
  margin-top: -10px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    width: 100px;
    margin-top: -20px;
  }
`;

const StyledBlagoomStandardLogo = styled(BlagoomStandardLogo)`
  margin: 20px 5px;
  width: 150px;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    margin: 17px 5px;
    width: 90px;
  }
`;

const RightWrapperMobileFiller = styled.div`
  width: 50px;

  @media only screen and (min-width: ${(props) =>
      props.theme.firstBreakpoint + 1}px) {
    display: none;
  }
`;
const RightWrapperWeb = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -20px;
`;

const ProfileOrLoginDropdownWrapper = styled.div`
  position: relative;
`;

const SubBarWrapper = styled.div`
  position: fixed;
  top: 84px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: white;
  z-index: 7;

  //width: 100%;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    top: 55px;
  }
`;

const CourseTitleAboWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  padding-left: 10px;
  //padding-right: 90px;
  //box-sizing: border-box;
  width: 100%;
  max-width: 100%;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-right: 0px;
  }
`;

const TitleUniWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    width: 100%;
  }
`;

const CourseTitle = styled.div`
  font-weight: bold;
  font-size: 38px;
  line-height: 52px;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    font-size: 16px;
  }
`;

const University = styled.div`
  font-weight: 500;
  font-size: 19px;
  margin-top: -5px;
  z-index: -1;

  @media only screen and (max-width: ${(props) =>
      props.theme.firstBreakpoint}px) {
    font-size: 12px;
    margin-top: -13px;
  }
`;
